import React from 'react'
import Helmet from 'react-helmet'
import { stringify } from 'qs'
import { serialize } from 'dom-form-serializer'



class Form extends React.Component {
  static defaultProps = {
    name: 'Simple Form Ajax',
    subject: '', // optional subject of the notification email
    action: '',
    successMessage: 'Merci pour votre message. Nous vous répondrons prochainement.',
    errorMessage:
      'Désolé. Il y a un problème avec le formulaire. Merci de re-essayer plus tard.'
  }

  state = {
    alert: '',
    disabled: false
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.disabled) return

    const form = e.target
    const data = serialize(form)
    this.setState({ disabled: true })
    fetch(form.action + '?' + stringify(data), {
      method: 'POST'
    })
      .then(res => {
        if (res.ok) {
          return res
        } else {
          throw new Error('Network error')
        }
      })
      .then(() => {
        form.reset()
        this.setState({
          alert: this.props.successMessage,
          disabled: false
        })
      })
      .catch(err => {
        console.error(err)
        this.setState({
          disabled: false,
          alert: this.props.errorMessage
        })
      })
  }

  render() {
    const { name, subject, action } = this.props

    return (
      <>
        <Helmet>
          <script src="https://www.google.com/recaptcha/api.js" />
        </Helmet>
        <form
          name={name}
          action={action}
          onSubmit={this.handleSubmit}
          data-netlify="true"
          data-netlify-recaptcha="true"
        >
         
          <div className="row">
            <div className="col">
              <input
                className="form-control"
                type="text"
                placeholder="Nom"
                name="lastname"
                required
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col">
              <input
                className="form-control"
                type="email"
                placeholder="Email"
                name="emailAddress"
                required
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col">
              <textarea className="form-control" name="message" rows="10" placeholder="Votre message"></textarea>
            </div>
          </div>
          <div
            className="g-recaptcha"
            data-sitekey="6LepZ7gUAAAAAAtFOADVhbFoa0eyZmKC1e7IbaGa"
          />
          {!!subject && <input type="hidden" name="subject" value={subject} />}
          <input type="hidden" name="form-name" value={name} />
          <div className="row mt-4">
            <div className="col">
              <button type="submit" className="btn btn-primary" disabled={this.state.disabled}>Envoyer</button>
            </div>
          </div>
           {this.state.alert && (
            <div className="row mt-4">
             <div className="col">
              <div class="alert alert-primary" role="alert">
                {this.state.alert}
              </div>
             </div>
           </div>
          )}
        </form>
        </>
    )
  }
}

export default Form
