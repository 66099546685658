import React, { Component } from 'react';
import SimpleContent from './SimpleContent'
import FormSimpleAjax from './FormSimpleAjax'

class ContactUs extends Component {
  render() {
    return (
      <section className="fdb-block pt-0">
        <div className="container">
          <div className="row mt-5">
            <div className="col-12 col-md-6 col-lg-5">
              <SimpleContent source={this.props.body} className="pt-0 pb-4"/>
            </div>

            <div className="col-12 col-md-6 ml-auto pt-5 pt-md-0">
              <FormSimpleAjax name="Contact" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default ContactUs
