import React from 'react'
import PropTypes from 'prop-types'

const PageHeader = ({
  title,
  subtitle,
  backgroundImage,
}) => {
  return (
    <section className="fdb-block fdb-viewport bg-dark bg-gradient-primary pt-126">
      <div className="container">
        <div className="row align-items-center text-left">
          <div className="col-12 col-sm-10 col-md-8 col-lg-8">
            <h1>{title}</h1>
            {subtitle && <p className="lead">{subtitle}</p>}
          </div>
        </div>
      </div>
    </section>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  backgroundImage: PropTypes.string,
}

export default PageHeader
